import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/SEO';
import Header from '../components/Header';

function showAttachedFile(item) {
  return (
    <li>
      <a className="btnLink" href={item.assetFile.asset.url}>
        {item.assetTitle}
      </a>
      <p>{item.description}</p>
    </li>
  );
}

function listAssets(assets) {
  const assetsListItems = [];
  assets.forEach((item) => {
    assetsListItems.push(showAttachedFile(item));
  });
  return assetsListItems;
}

const CallOutActionStyles = styled.div`
  /* width: clamp(75vw, 50%, 1000px); */
  text-align: center;
  a:hover {
    /* background: var(--darkGreenDarker); */
    text-decoration: underline;
    color: #fff;
  }
  ul {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0;
  }
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  li p {
    max-width: 75%;
  }
  .btnLink {
    font-family: Public_Sans;
    font-weight: 800;
    letter-spacing: 1px;
    display: inline-block;
    margin-top: clamp(1.5rem, 7vw, 3rem);
    font-size: clamp(16px, 4vw, 22px);
    line-height: 30px;
    padding: 15px 20px;
    background: var(--darkPurple);
    color: var(--white);
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 767px) {
      width: 70%;
    }
  }
`;

export default function AssetLibrary({ location }) {
  const data = useStaticQuery(graphql`
    query AssetLibraryQuery {
      assetItems: allSanityAsset {
        nodes {
          id
          title
          assetTitle
          description
          assetFile {
            asset {
              url
            }
          }
        }
      }
    }
  `);
  const assets = data.assetItems.nodes;
  assets.forEach((item) => console.log(item.assetTitle));
  return (
    <>
      <SEO location={location} title="Asset Library" />

      <Header />
      <main>
        <section className="center">
          <h1>Asset Library</h1>
        </section>
        <p>
          The OneSource Coalition Asset Library features principles and guidance
          that have been developed by Signatories, related to the Coalition’s
          three priorities. The Asset Library is intended to be a resource for
          Coalition Signatories and other stakeholders in the space. Inclusion
          of a resource here does not represent an endorsement of any document
          by any or all of the signatories.
        </p>
        <CallOutActionStyles>
          <ul>{assets.length > 0 ? listAssets(assets) : ''}</ul>
        </CallOutActionStyles>
      </main>
    </>
  );
}
